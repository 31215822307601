export const appVersionTitle = 'Logistics App 1.0.0';

export const headerDateFormat = 'dddd Do MMMM YYYY';

export const theme = {
  font: 'Verdana, Arial, Tahoma, sans-serif',
  colors: {
    error: '#ff0000',
    primary: '#7a8e8d',
    heading: '#5D6797',
    pageHeading: '#c5651f',
    gray2: '#aeaeae',
    border: '#aeaeae',
    darkBorder: '#636a0c',
    toolTipBackground: '#dde6e7',
    pwg: '#858b1f',
    lightGreen: '#e0e4c2',
    dateBackground: '#eff2de',
    dashboardTitle: '#545b21',
    loadingMessagesBackground: '#858b1f',
    form: {
      invalidInputHighlight: '#ff0000'
    },
    table: {
      tableHeader: '#dbe5c5',
      tableCellBorder: '#ccc',
      rowBackgroundColourOdd: '#f6fbe7',
      rowBackgroundColourEven: '#fff',
      rowBackgroundColourHighlight: '#dbe5c5'
    },
    sideMenu: {
      background: '#f3f5e5',
      backgroundSelected: '#c7cf93',
      backgroundUnselected: '#e0e4c2',
      backgroundSubmenu: '#fff8cb',
      backgroundGrey: '#efefef',
      borderBottom: '#989b82'
    },
    header: {
      tabBackgroundLight: '#f2f6e4',
      tabBackground: '#d0d3b1',
      tabBorder: '#d0d3b1'
    }
  },
  zIndexes: {
    menu: 999
  }
};
