import React from 'react';
import styled from 'styled-components';
import { MdDone, MdClose } from 'react-icons/all';

const FailingCheckTableContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const TableHeader = styled.th`
  height: 35px;
  margin: 0px;
  padding: 0px;
  border-bottom: 1px solid #bbbbbb;
`;

const TableRow = styled.tr`
  height: 27px;
  font-weight: ${props => (props.failingCheck ? 'bold' : 'normal')};
`;

class FailingCheckTable extends React.Component {
  addTableRow = (question, uniqueKey) => {
    return (
      <TableRow key={uniqueKey}>
        <td>
          {parseInt(question.question_index) + 1}
          {': '}
          {question.question}
        </td>
        {question.answer === '1' && (
          <td style={{ textAlign: 'center', color: '#669933' }}>
            <MdDone size={16} />
          </td>
        )}
        {question.answer === '0' && (
          <td style={{ textAlign: 'center', color: '#ff0000' }}>
            <MdClose size={16} />
          </td>
        )}
      </TableRow>
    );
  };

  createProductTable = (check, index) => {
    let productTable = [];
    let tableRows = [];
    let uniqueKey = 0;

    if (check.failingChecks.length > 0) {
      for (let i = 0; i < check.failingChecks.length; i++) {
        tableRows.push(this.addTableRow(check.failingChecks[i], uniqueKey));
        uniqueKey += 1;
      }

      if (!this.props.isShowOnlyFailedChecks) {
        for (let i = 0; i < check.passingChecks.length; i++) {
          tableRows.push(this.addTableRow(check.passingChecks[i], uniqueKey));
          uniqueKey += 1;
        }
      }
    } else if (!this.props.isShowOnlyFailedChecks) {
      for (let i = 0; i < check.passingChecks.length; i++) {
        tableRows.push(this.addTableRow(check.passingChecks[i], uniqueKey));
        uniqueKey += 1;
      }
    }

    if (tableRows.length > 0) {
      productTable.push(
        <div key={index} style={{ fontSize: 20 }}>
          Item {index + 1}: {check.product_title} {check.itemCode}
        </div>
      );
      productTable.push(
        <table
          key={`${index}_table`}
          style={{ width: '100%', borderCollapse: 'collapse', marginBottom: 20 }}
        >
          <thead>
            <TableRow style={{ textAlign: 'left' }}>
              <TableHeader style={{ width: '85%' }}>Question</TableHeader>
              <TableHeader style={{ textAlign: 'center' }}>Response</TableHeader>
            </TableRow>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      );
    }
    return productTable;
  };

  render() {
    const { checksArray } = this.props;
    return (
      <FailingCheckTableContainer>
        {checksArray.map((check, index) => this.createProductTable(check, index))}
      </FailingCheckTableContainer>
    );
  }
}

export default FailingCheckTable;
