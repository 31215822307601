import React from 'react';
import { BarChart, Bar, Brush, XAxis, YAxis, Tooltip } from 'recharts';

const CustomTooltip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div style={{ backgroundColor: 'white' }}>
        <p>{`Week ${label}`}</p>
        <p>{`Pass: ${payload[0].payload[payload[0].name]}`}</p>
        <p>{`Fail: ${payload[1].payload[payload[1].name]}`}</p>
        <p>{`Todo: ${payload[2].payload[payload[2].name]}`}</p>
      </div>
    );
  }

  return null;
};

const BAR_WIDTH = 50;

const simpleCharts = {
  QaStatusChart: props => (
    <BarChart width={500} height={500} data={props.chartData}>
      <XAxis interval={4} dataKey={props.XAxisDataKey} />
      <YAxis type="number" allowDecimals={false} tickInterval={1} />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey={props.YAxisDataKey1} fill="#009933" barSize={BAR_WIDTH} stackId="a" />
      <Bar dataKey={props.YAxisDataKey2} fill="#b30000" barSize={BAR_WIDTH} stackId="a" />
      <Bar dataKey={props.YAxisDataKey3} fill="#234C73" barSize={BAR_WIDTH} stackId="a" />
      <Brush height={20} stroke="#8884d8" {...props.brush} />
    </BarChart>
  ),
  ReviewChart: props => (
    <BarChart width={500} height={500} data={props.chartData}>
      <XAxis interval={4} dataKey={props.XAxisDataKey} />
      <YAxis type="number" allowDecimals={false} tickInterval={1} />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey={props.YAxisDataKey1} fill="#009933" barSize={BAR_WIDTH} stackId="a" />
      <Bar dataKey={props.YAxisDataKey2} fill="#b30000" barSize={BAR_WIDTH} stackId="a" />
      <Bar dataKey={props.YAxisDataKey3} fill="#234C73" barSize={BAR_WIDTH} stackId="a" />
      <Brush height={20} stroke="#8884d8" {...props.brush} />
    </BarChart>
  )
};
export default simpleCharts;
