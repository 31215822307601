import axios from 'axios';

const pdfBaseUrl = 'https://doc.pwg-apps.co.uk';
// const pdfBaseUrl = 'http://localhost:4000';
const controlCentreBaseUrl = 'https://doc.pwg-apps.co.uk';
// const controlCentreBaseUrl = 'http://localhost:4000';

export const pdf_server = {
  call: (url, params) => {
    return axios.post(`${pdfBaseUrl}${url}`, params).catch(error => {
      console.log('Axios error', error);
      return error.response;
    });
  }
};

export const control_centre_server = {
  call: (url, params) => {
    return axios.post(`${controlCentreBaseUrl}${url}`, params).catch(error => {
      console.log('Axios error', error);
      return error.response;
    });
  }
};
