import styled from "styled-components";

import { theme } from "../constants";

const AppContainer = styled.div`
  position: absolute;
  left: 50%;
  margin-top: 0px;
  margin-left: -480px;
  font-family: ${theme.font};
  width: 960px;
  font-size: 13px;
`;

export default AppContainer;
