import styled from "styled-components";
import { theme } from "../../constants";

const SideMenuDiv = styled.div`
  background-color: ${theme.colors.sideMenu.background};
  border-right: 1px solid #bfbfbf;
  height: 100%;
  font-size: 13.5px;
  width: 285px;
  flex-basis: auto;
  min-height: 500px;
`;

export default SideMenuDiv;

