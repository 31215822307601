import React, { Component } from 'react';

import AppContent from './AppContent';
import PageHeading from './PageHeading';

import { Link } from 'react-router-dom';

import { getReviewData } from '../data';

const START_YEAR = 2019;
const THIS_YEAR = new Date().getFullYear();

class QAChart extends Component {
  state = {
    reviewsData: [],
    selectedWeek: 'ALL',
    selectedYear: THIS_YEAR,
    selectedSupplier: 'ALL',
    selectedDealer: 'ALL',
    selectedProduct: 'ALL',
    selectedStream: 'ALL',
    isLoading: true
  };

  /************************************************************************/

  componentDidMount = async () => {
    const {
      selectedWeek,
      selectedYear,
      selectedSupplier,
      selectedDealer,
      selectedProduct,
      selectedStream
    } = this.state;

    const response = await getReviewData(this.props.token);

    const yearsForSelect = this.getYearsForSelect();

    let weeksForSelect = [];
    let dealersForSelect = [];
    let productsForSelect = [];

    let reviewsData = null;
    let filteredData = {
      itemData: [],
      currentData: [],
      totalNumberOfItems: 0,
      totalNumberOfPassingItems: 0,
      totalNumberOfSupplierOrders: 0,
      totolNumberOfPassingSupplierOrders: 0
    };

    if (response && response.data && response.data.failingItems) {
      reviewsData = response.data.failingItems;

      weeksForSelect = this.getWeeksForSelect(reviewsData, selectedYear);
      dealersForSelect = this.getDealersForSelect(reviewsData, selectedYear, selectedStream);
      productsForSelect = this.getProductsForSelect(reviewsData, selectedYear);

      filteredData = this.getFilteredData(
        reviewsData,
        selectedWeek,
        selectedYear,
        selectedSupplier,
        selectedDealer,
        selectedProduct,
        selectedStream
      );
    }

    await this.setState({
      isLoading: false,
      reviewsData,
      filteredData,
      weeksForSelect,
      yearsForSelect,
      dealersForSelect,
      productsForSelect
    });
  };

  /************************************************************************/

  getFilteredData = (data, week, year, supplier, dealer, product, stream) => {
    let currentData = [];

    let itemIds = {};
    let passingItemIds = {};
    let failingItemIds = {};
    let clearItemIds = {};

    let supplierRefs = {};
    let passingSupplierRefs = {};
    let failingSupplierRefs = {};
    let clearSupplierRefs = {};

    let questions = [];
    let answers = [];
    for (let i = 0; i < data.length; i++) {
      if (
        (week === 'ALL' || data[i].arrival_week === `${week}`) &&
        data[i].arrival_year === `${year}` &&
        (supplier === 'ALL' || data[i].sup_id === `${supplier}`) &&
        (dealer === 'ALL' || data[i].dealer_title === `${dealer}`) &&
        (product === 'ALL' || data[i].product_title === `${product}`) &&
        (stream === 'ALL' || data[i].stream_id === `${stream}`)
      ) {
        currentData.push(data[i]);
        if (!supplierRefs[data[i].supplier_ref]) {
          supplierRefs[data[i].supplier_ref] = 1;
        }
        if (!itemIds[data[i].item_id]) {
          itemIds[data[i].item_id] = 1;
        }
        questions = data[i].questions.split(',');
        answers = data[i].answers.split(',');

        if (data[i].answers.includes('0')) {
          if (!failingSupplierRefs[data[i].supplier_ref]) {
            failingSupplierRefs[data[i].supplier_ref] = [
              {
                review_id: data[i].review_id,
                order_id: data[i].order_id,
                sup_id: data[i].sup_id,
                questions,
                answers,
                createdAt: data[i].createdAt
              }
            ];
          } else {
            failingSupplierRefs[data[i].supplier_ref].push({
              review_id: data[i].review_id,
              order_id: data[i].order_id,
              sup_id: data[i].sup_id,
              questions,
              answers,
              createdAt: data[i].createdAt
            });
          }
          if (!failingItemIds[data[i].item_id]) {
            failingItemIds[data[i].item_id] = 1;
          }
        } else {
          if (!passingSupplierRefs[data[i].supplier_ref]) {
            passingSupplierRefs[data[i].supplier_ref] = [
              {
                review_id: data[i].review_id,
                order_id: data[i].order_id,
                sup_id: data[i].sup_id,
                questions,
                answers,
                createdAt: data[i].createdAt
              }
            ];
          } else {
            passingSupplierRefs[data[i].supplier_ref].push({
              review_id: data[i].review_id,
              order_id: data[i].order_id,
              sup_id: data[i].sup_id,
              questions,
              answers,
              createdAt: data[i].createdAt
            });
          }
          if (!passingItemIds[data[i].item_id]) {
            passingItemIds[data[i].item_id] = 1;
          }
        }
      }
    }

    clearSupplierRefs = { ...passingSupplierRefs };
    let failingKeys = Object.keys(failingSupplierRefs);
    for (let i = 0; i < failingKeys.length; i++) {
      if (clearSupplierRefs[failingKeys[i]]) {
        delete clearSupplierRefs[failingKeys[i]];
      }
    }

    clearItemIds = { ...passingItemIds };
    failingKeys = Object.keys(failingItemIds);
    for (let i = 0; i < failingKeys.length; i++) {
      if (clearItemIds[failingKeys[i]]) {
        delete clearItemIds[failingKeys[i]];
      }
    }

    return {
      currentData,
      failingSupplierRefs,
      passingSupplierRefs,
      totalNumberOfItems: Object.keys(itemIds).length,
      totalNumberOfPassingItems: Object.keys(clearItemIds).length,
      totalNumberOfSupplierOrders: Object.keys(supplierRefs).length,
      totolNumberOfPassingSupplierOrders: Object.keys(clearSupplierRefs).length
    };
  };

  /************************************************************************/

  handleSelectChange = event => {
    const { reviewsData } = this.state;
    let { weeksForSelect, dealersForSelect, productsForSelect } = this.state;
    const params = {
      selectedWeek: this.state.selectedWeek,
      selectedYear: this.state.selectedYear,
      selectedSupplier: this.state.selectedSupplier,
      selectedDealer: this.state.selectedDealer,
      selectedProduct: this.state.selectedProduct,
      selectedStream: this.state.selectedStream
    };

    let selectedName = null;
    if (event.target && event.target.name && event.target.value) {
      params[event.target.name] = event.target.value;
      selectedName = event.target.name;
    }

    if (selectedName === 'selectedYear') {
      params.selectedWeek = 'ALL';
      params.selectedDealer = 'ALL';
      params.selectedProduct = 'ALL';
    } else if (selectedName === 'selectedStream') {
      params.selectedDealer = 'ALL';
      params.selectedProduct = 'ALL';
    }

    const filteredData = this.getFilteredData(
      reviewsData,
      params.selectedWeek,
      params.selectedYear,
      params.selectedSupplier,
      params.selectedDealer,
      params.selectedProduct,
      params.selectedStream
    );

    if (selectedName === 'selectedYear') {
      weeksForSelect = this.getWeeksForSelect(filteredData.currentData, params.selectedYear);
      dealersForSelect = this.getDealersForSelect(
        filteredData.currentData,
        params.selectedYear,
        params.selectedStream
      );
      productsForSelect = this.getProductsForSelect(filteredData.currentData, params.selectedYear);
    }

    if (selectedName === 'selectedStream') {
      dealersForSelect = this.getDealersForSelect(
        filteredData.currentData,
        params.selectedYear,
        params.selectedStream
      );
      productsForSelect = this.getProductsForSelect(filteredData.currentData, params.selectedYear);
    }

    this.setState({
      filteredData,
      weeksForSelect,
      dealersForSelect,
      productsForSelect,
      ...params
    });
  };

  /************************************************************************/

  createReviewRows = (failingData, passingData) => {
    let failedQuestions = [];
    let keys = Object.keys(failingData);
    for (let i = 0; i < keys.length; i++) {
      let tempQuestions = {};
      for (let j = 0; j < failingData[keys[i]].length; j++) {
        if (failingData[keys[i]][j].questions) {
          for (let k = 0; k < failingData[keys[i]][j].questions.length; k++) {
            if (!tempQuestions[k]) {
              // no failing question exists for that index
              if (failingData[keys[i]][j].answers[k] === '0') {
                tempQuestions[k] = {
                  supplier_ref: keys[i],
                  order_id: failingData[keys[i]][j].order_id,
                  sup_id: failingData[keys[i]][j].sup_id,
                  review_id: failingData[keys[i]][j].review_id,
                  date_raised: failingData[keys[i]][j].createdAt,
                  date_checked: failingData[keys[i]][j].createdAt,
                  date_fixed: null,
                  question: failingData[keys[i]][j].questions[k]
                };
              }
            } else if (tempQuestions[k].date_checked < failingData[keys[i]][j].createdAt) {
              // failing question exists, update date checked if newer
              if (failingData[keys[i]][j].answers[k] === '0') {
                tempQuestions[k].date_checked = failingData[keys[i]][j].createdAt;
              }

              // failed question exists, has been resolved by review that overall fails if review is newer
              if (!tempQuestions[k].date_fixed && failingData[keys[i]][j].answers[k] === '1') {
                tempQuestions[k].date_checked = failingData[keys[i]][j].createdAt;
                tempQuestions[k].date_fixed = failingData[keys[i]][j].createdAt;
              }
            }
          }
        }
      }

      if (passingData[keys[i]]) {
        for (let j = 0; j < passingData[keys[i]].length; j++) {
          for (let k = 0; k < passingData[keys[i]][j].questions.length; k++) {
            if (tempQuestions[k] && !tempQuestions[k].date_fixed) {
              if (passingData[keys[i]][j].createdAt > tempQuestions[k].date_checked) {
                tempQuestions[k].date_checked = passingData[keys[i]][j].createdAt;
                tempQuestions[k].date_fixed = passingData[keys[i]][j].createdAt;
              }
            }
          }
        }
      }
      failedQuestions = [...failedQuestions, ...Object.values(tempQuestions)];
    }

    failedQuestions = failedQuestions.sort(function(a, b) {
      return a.date_raised < b.date_raised ? -1 : a.date_raised > b.date_raised ? 1 : 0;
    });

    let reviewRows = [];
    for (let i = 0; i < failedQuestions.length; i++) {
      reviewRows.push(
        <tr key={`review_rows_${i}`}>
          <td style={{ height: '50px' }}>{failedQuestions[i].date_raised}</td>
          <td style={{ height: '50px' }}>
            <div>
              <Link
                to={`/failingChecks/${failedQuestions[i].order_id}/${failedQuestions[i].sup_id}/${
                  failedQuestions[i].review_id
                }`}
              >
                {`Review-${failedQuestions[i].review_id}`}
              </Link>
            </div>
            <div>{failedQuestions[i].question}</div>
          </td>
          <td style={{ height: '50px' }}>
            {failedQuestions[i].date_fixed && `Passed at ${failedQuestions[i].date_fixed}`}
            {!failedQuestions[i].date_fixed &&
              failedQuestions[i].date_raised === failedQuestions[i].date_checked &&
              `Failed initial check`}
            {!failedQuestions[i].date_fixed &&
              failedQuestions[i].date_raised !== failedQuestions[i].date_checked &&
              `Rechecked at ${failedQuestions[i].date_checked}`}
          </td>
        </tr>
      );
    }

    return reviewRows;
  };

  /************************************************************************/

  calculatePassRate = (passing, total) => {
    let passRate = 'No review data';
    if (total > 0) {
      if (passing) {
        passRate = `${((passing * 100) / total).toFixed(2)}%`;
      } else {
        passRate = `${((0 * 100) / total).toFixed(2)}%`;
      }
    }
    return passRate;
  };

  /************************************************************************/

  getWeeksForSelect = (reviewsData, selectedYear) => {
    let weeks = {};
    for (let i = 0; i < reviewsData.length; i++) {
      if (reviewsData[i].arrival_year === `${selectedYear}`) {
        if (!weeks[reviewsData[i].arrival_week]) {
          weeks[reviewsData[i].arrival_week] = 1;
        }
      }
    }

    const weeksArray = Object.keys(weeks).sort();
    let weeksForSelect = [];
    for (let i = 0; i < weeksArray.length; i++) {
      weeksForSelect.push(
        <option key={`select_weeks_${weeksArray[i]}`} value={weeksArray[i]}>
          {weeksArray[i]}
        </option>
      );
    }

    return weeksForSelect;
  };

  getYearsForSelect = () => {
    let years = [];
    for (let i = START_YEAR; i <= THIS_YEAR; i++) {
      years.push(
        <option key={`select_years_${i}`} value={i}>
          {i}
        </option>
      );
    }
    return years;
  };

  getDealersForSelect = (reviewsData, selectedYear, selectedStream) => {
    let dealers = {};
    for (let i = 0; i < reviewsData.length; i++) {
      if (
        reviewsData[i].arrival_year === `${selectedYear}` &&
        (selectedStream === 'ALL' || reviewsData[i].stream_id === `${selectedStream}`)
      ) {
        if (!dealers[reviewsData[i].dealer_title]) {
          dealers[reviewsData[i].dealer_title] = 1;
        }
      }
    }

    const dealersArray = Object.keys(dealers).sort();
    let dealersForSelect = [];
    for (let i = 0; i < dealersArray.length; i++) {
      dealersForSelect.push(
        <option key={`select_dealers_${dealersArray[i]}`} value={dealersArray[i]}>
          {dealersArray[i]}
        </option>
      );
    }
    return dealersForSelect;
  };

  getProductsForSelect = (reviewsData, selectedYear) => {
    let products = {};
    for (let i = 0; i < reviewsData.length; i++) {
      if (reviewsData[i].arrival_year === `${selectedYear}`) {
        if (!products[reviewsData[i].product_title]) {
          products[reviewsData[i].product_title] = 1;
        }
      }
    }

    const productsArray = Object.keys(products).sort();

    let productsForSelect = [];
    for (let i = 0; i < productsArray.length; i++) {
      productsForSelect.push(
        <option key={`select_products_${productsArray[i]}`} value={productsArray[i]}>
          {productsArray[i]}
        </option>
      );
    }
    return productsForSelect;
  };

  /************************************************************************/

  render() {
    const {
      isLoading,
      filteredData,
      weeksForSelect,
      yearsForSelect,
      dealersForSelect,
      productsForSelect,
      selectedYear,
      selectedWeek,
      selectedSupplier,
      selectedProduct,
      selectedStream,
      selectedDealer
    } = this.state;

    let reviewRows = [];
    if (filteredData) {
      reviewRows = this.createReviewRows(
        filteredData.failingSupplierRefs,
        filteredData.passingSupplierRefs
      );
    }
    return (
      <AppContent>
        <PageHeading>Logistics App - All Failing Checks</PageHeading>
        {isLoading && 'Loading'}
        {!isLoading && (
          <div>
            <div style={{ paddingLeft: '0px', paddingBottom: '10px', textAlign: 'left' }}>
              <span style={{ paddingRight: '15px' }}>
                <span style={{ paddingRight: '5px' }}>Year:</span>
                <select name="selectedYear" onChange={this.handleSelectChange} value={selectedYear}>
                  {yearsForSelect}
                </select>
              </span>

              <span style={{ paddingRight: '15px' }}>
                <span style={{ paddingRight: '5px' }}>Week:</span>
                <select name="selectedWeek" onChange={this.handleSelectChange} value={selectedWeek}>
                  <option value={'ALL'}>All</option>
                  {weeksForSelect}
                </select>
              </span>
            </div>

            <div style={{ paddingLeft: '0px', paddingBottom: '10px', textAlign: 'left' }}>
              <span style={{ paddingRight: '15px' }}>
                <span style={{ paddingRight: '5px' }}>Supplier:</span>
                <select
                  name="selectedSupplier"
                  onChange={this.handleSelectChange}
                  value={selectedSupplier}
                >
                  <option value={'ALL'}>All</option>
                  <option value={'1'}>Pinus</option>
                  <option value={'2'}>Byko</option>
                  <option value={'3'}>Tiptree</option>
                  <option value={'5'}>Mumford</option>
                </select>
              </span>

              <span style={{ paddingRight: '15px' }}>
                <span style={{ paddingRight: '5px' }}>Product:</span>
                <select
                  style={{ width: '220px' }}
                  name="selectedProduct"
                  onChange={this.handleSelectChange}
                  value={selectedProduct}
                >
                  <option value={'ALL'}>All</option>
                  {productsForSelect}
                </select>
              </span>
            </div>

            <div style={{ paddingLeft: '0px', paddingBottom: '10px', textAlign: 'left' }}>
              <span style={{ paddingRight: '15px' }}>
                <span style={{ paddingRight: '5px' }}>Stream:</span>
                <select
                  name="selectedStream"
                  onChange={this.handleSelectChange}
                  value={selectedStream}
                >
                  <option value={'ALL'}>All</option>
                  <option value={'4'}>TW</option>
                  <option value={'2'}>Dale</option>
                </select>
              </span>

              <span style={{ paddingRight: '15px' }}>
                <span style={{ paddingRight: '5px' }}>Dealer:</span>
                <select
                  style={{ width: '330px' }}
                  name="selectedDealer"
                  onChange={this.handleSelectChange}
                  value={selectedDealer}
                >
                  <option value={'ALL'}>All</option>
                  {dealersForSelect}
                </select>
              </span>
            </div>

            <hr />
            {filteredData && (
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <div>
                  {`Supplier order pass rate: ${filteredData.totolNumberOfPassingSupplierOrders}/${
                    filteredData.totalNumberOfSupplierOrders
                  } (${this.calculatePassRate(
                    filteredData.totolNumberOfPassingSupplierOrders,
                    filteredData.totalNumberOfSupplierOrders
                  )})`}
                </div>
                <div>{`Item pass rate: ${filteredData.totalNumberOfPassingItems}/${
                  filteredData.totalNumberOfItems
                } (${this.calculatePassRate(
                  filteredData.totalNumberOfPassingItems,
                  filteredData.totalNumberOfItems
                )})`}</div>
              </div>
            )}

            <table style={{ width: '100%', paddingTop: '10px' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', width: '25%' }}>Date check failed</th>
                  <th style={{ textAlign: 'left', width: '50%' }}>
                    Supplier order and failing question
                  </th>
                  <th style={{ textAlign: 'left', width: '25%' }}>Current status</th>
                </tr>
              </thead>
              <tbody>{reviewRows}</tbody>
            </table>
          </div>
        )}
      </AppContent>
    );
  }
}

export default QAChart;
