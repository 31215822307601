import styled from "styled-components";

const HeaderControls = styled.div`
  position: absolute;
  top: 12px;
  right: calc(50% - 480px);
  height: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
`;

export default HeaderControls;
