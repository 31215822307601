import styled from "styled-components";
import { theme } from "../../constants";

const DateDisplay = styled.div`
  background-color: ${theme.colors.dateBackground};
  vertical-align: center;
  padding: 5px 10px 5px 10px;
  margin-left: 20px;
`;

export default DateDisplay;
