import React, { Component } from 'react';

import AppContent from './AppContent';
import PageHeading from './PageHeading';
import simpleCharts from './simpleCharts';

import { getQADataForGraph } from '../data';
// getReviewData
const QaStatusChart = simpleCharts.QaStatusChart;
const ReviewChart = simpleCharts.ReviewChart;

const START_YEAR = 2019;
const THIS_YEAR = new Date().getFullYear();
const NEXT_YEAR = THIS_YEAR + 1;
const MAX_WEEK_NUMBER = 53;

class QAChart extends Component {
  state = {
    currentReviewsByYear: {},
    selectedYear: THIS_YEAR,
    stream: 'all',
    factory: 'all',
    isLoading: true
  };

  componentDidMount = async () => {
    const reviewsData = await getQADataForGraph(this.props.token);
    const dateTime = new Date();
    let currentReviewsByYear = {};

    if (reviewsData) {
      for (let i = START_YEAR; i < NEXT_YEAR; i++) {
        let reviews = [];
        for (let j = 1; j <= MAX_WEEK_NUMBER; j++) {
          let weekReview = {
            arrival_week: j,

            qa_passed_all_all: 0,
            qa_failed_all_all: 0,
            qa_todo_all_all: 0,

            qa_passed_tw_all: 0,
            qa_failed_tw_all: 0,
            qa_todo_tw_all: 0,

            qa_passed_dale_all: 0,
            qa_failed_dale_all: 0,
            qa_todo_dale_all: 0,

            qa_passed_all_pinus: 0,
            qa_failed_all_pinus: 0,
            qa_todo_all_pinus: 0,

            qa_passed_all_byko: 0,
            qa_failed_all_byko: 0,
            qa_todo_all_byko: 0,

            qa_passed_all_tiptree: 0,
            qa_failed_all_tiptree: 0,
            qa_todo_all_tiptree: 0,

            qa_passed_all_mumford: 0,
            qa_failed_all_mumford: 0,
            qa_todo_all_mumford: 0,

            qa_passed_tw_pinus: 0,
            qa_failed_tw_pinus: 0,
            qa_todo_tw_pinus: 0,

            qa_passed_tw_byko: 0,
            qa_failed_tw_byko: 0,
            qa_todo_tw_byko: 0,

            qa_passed_tw_tiptree: 0,
            qa_failed_tw_tiptree: 0,
            qa_todo_tw_tiptree: 0,

            qa_passed_tw_mumford: 0,
            qa_failed_tw_mumford: 0,
            qa_todo_tw_mumford: 0,

            qa_passed_dale_pinus: 0,
            qa_failed_dale_pinus: 0,
            qa_todo_dale_pinus: 0,

            qa_passed_dale_byko: 0,
            qa_failed_dale_byko: 0,
            qa_todo_dale_byko: 0,

            qa_passed_dale_tiptree: 0,
            qa_failed_dale_tiptree: 0,
            qa_todo_dale_tiptree: 0,

            qa_passed_dale_mumford: 0,
            qa_failed_dale_mumford: 0,
            qa_todo_dale_mumford: 0
          };
          for (let k = 0; k < reviewsData.data.length; k++) {
            if (
              reviewsData.data[k].arrival_week === `${j}` &&
              reviewsData.data[k].arrival_year === `${i}`
            ) {
              if (reviewsData.data[k].qa_status === 'passed') {
                weekReview.qa_passed_all_all += 1;

                if (reviewsData.data[k].stream_id === '4') {
                  weekReview.qa_passed_tw_all += 1;
                  if (reviewsData.data[k].sup_id === '1') {
                    weekReview.qa_passed_tw_pinus += 1;
                  } else if (reviewsData.data[k].sup_id === '2') {
                    weekReview.qa_passed_tw_byko += 1;
                  } else if (reviewsData.data[k].sup_id === '3') {
                    weekReview.qa_passed_tw_tiptree += 1;
                  } else if (reviewsData.data[k].sup_id === '5') {
                    weekReview.qa_passed_tw_mumford += 1;
                  }
                } else if (reviewsData.data[k].stream_id === '2') {
                  weekReview.qa_passed_dale_all += 1;
                  if (reviewsData.data[k].sup_id === '1') {
                    weekReview.qa_passed_dale_pinus += 1;
                  } else if (reviewsData.data[k].sup_id === '2') {
                    weekReview.qa_passed_dale_byko += 1;
                  } else if (reviewsData.data[k].sup_id === '3') {
                    weekReview.qa_passed_dale_tiptree += 1;
                  } else if (reviewsData.data[k].sup_id === '5') {
                    weekReview.qa_passed_dale_mumford += 1;
                  }
                }

                if (reviewsData.data[k].sup_id === '1') {
                  weekReview.qa_passed_all_pinus += 1;
                } else if (reviewsData.data[k].sup_id === '2') {
                  weekReview.qa_passed_all_byko += 1;
                } else if (reviewsData.data[k].sup_id === '3') {
                  weekReview.qa_passed_all_tiptree += 1;
                } else if (reviewsData.data[k].sup_id === '5') {
                  weekReview.qa_passed_all_mumford += 1;
                }
              } else if (reviewsData.data[k].qa_status === 'failed') {
                weekReview.qa_failed_all_all += 1;

                if (reviewsData.data[k].stream_id === '4') {
                  weekReview.qa_failed_tw_all += 1;
                  if (reviewsData.data[k].sup_id === '1') {
                    weekReview.qa_failed_tw_pinus += 1;
                  } else if (reviewsData.data[k].sup_id === '2') {
                    weekReview.qa_failed_tw_byko += 1;
                  } else if (reviewsData.data[k].sup_id === '3') {
                    weekReview.qa_failed_tw_tiptree += 1;
                  } else if (reviewsData.data[k].sup_id === '5') {
                    weekReview.qa_failed_tw_mumford += 1;
                  }
                } else if (reviewsData.data[k].stream_id === '2') {
                  weekReview.qa_failed_dale_all += 1;
                  if (reviewsData.data[k].sup_id === '1') {
                    weekReview.qa_failed_dale_pinus += 1;
                  } else if (reviewsData.data[k].sup_id === '2') {
                    weekReview.qa_failed_dale_byko += 1;
                  } else if (reviewsData.data[k].sup_id === '3') {
                    weekReview.qa_failed_dale_tiptree += 1;
                  } else if (reviewsData.data[k].sup_id === '5') {
                    weekReview.qa_failed_dale_mumford += 1;
                  }
                }

                if (reviewsData.data[k].sup_id === '1') {
                  weekReview.qa_failed_all_pinus += 1;
                } else if (reviewsData.data[k].sup_id === '2') {
                  weekReview.qa_failed_all_byko += 1;
                } else if (reviewsData.data[k].sup_id === '3') {
                  weekReview.qa_failed_all_tiptree += 1;
                } else if (reviewsData.data[k].sup_id === '5') {
                  weekReview.qa_failed_all_mumford += 1;
                }
              } else {
                weekReview.qa_todo_all_all += 1;

                if (reviewsData.data[k].stream_id === '4') {
                  weekReview.qa_todo_tw_all += 1;
                  if (reviewsData.data[k].sup_id === '1') {
                    weekReview.qa_todo_tw_pinus += 1;
                  } else if (reviewsData.data[k].sup_id === '2') {
                    weekReview.qa_todo_tw_byko += 1;
                  } else if (reviewsData.data[k].sup_id === '3') {
                    weekReview.qa_todo_tw_tiptree += 1;
                  } else if (reviewsData.data[k].sup_id === '5') {
                    weekReview.qa_todo_tw_mumford += 1;
                  }
                } else if (reviewsData.data[k].stream_id === '2') {
                  weekReview.qa_todo_dale_all += 1;
                  if (reviewsData.data[k].sup_id === '1') {
                    weekReview.qa_todo_dale_pinus += 1;
                  } else if (reviewsData.data[k].sup_id === '2') {
                    weekReview.qa_todo_dale_byko += 1;
                  } else if (reviewsData.data[k].sup_id === '3') {
                    weekReview.qa_todo_dale_tiptree += 1;
                  } else if (reviewsData.data[k].sup_id === '5') {
                    weekReview.qa_todo_dale_mumford += 1;
                  }
                }

                if (reviewsData.data[k].sup_id === '1') {
                  weekReview.qa_todo_all_pinus += 1;
                } else if (reviewsData.data[k].sup_id === '2') {
                  weekReview.qa_todo_all_byko += 1;
                } else if (reviewsData.data[k].sup_id === '3') {
                  weekReview.qa_todo_all_tiptree += 1;
                } else if (reviewsData.data[k].sup_id === '5') {
                  weekReview.qa_todo_all_mumford += 1;
                }
              }
            }
          }
          reviews.push(weekReview);
        }
        currentReviewsByYear[i] = reviews;
      }
    }

    await this.setState({
      currentReviewsByYear,
      dateTime: dateTime.toUTCString(),
      isLoading: false
    });
  };

  handleYearChange = event => {
    this.setState({
      selectedYear: event.target.value
    });
  };

  handleStreamChange = event => {
    this.setState({
      stream: event.target.value
    });
  };

  handleFactoryChange = event => {
    this.setState({
      factory: event.target.value
    });
  };

  render() {
    const {
      isLoading,
      dateTime,
      factory,
      stream,
      currentReviewsByYear,
      selectedYear,
      itemPassRateByYear,
      supplierOrderPassRateByYear
    } = this.state;

    return (
      <AppContent>
        <PageHeading>Logistics App - graph of live checks</PageHeading>
        <p>Graph showing number of supplier orders by current QA status on {dateTime}</p>
        <div>
          QA Status:{' '}
          <div
            style={{
              width: '60px',
              height: '14px',
              background: '#009933',
              display: 'inline-block'
            }}
          />{' '}
          All items passed{' '}
          <div
            style={{
              width: '60px',
              height: '14px',
              background: '#b30000',
              display: 'inline-block'
            }}
          />{' '}
          Failing Checks{' '}
          <div
            style={{
              width: '60px',
              height: '14px',
              background: '#234C73',
              display: 'inline-block'
            }}
          />{' '}
          To be completed
        </div>
        <hr />
        {isLoading && 'Loading'}
        {!isLoading && (
          <div style={{ paddingLeft: '0px', textAlign: 'center' }}>
            <span style={{ paddingRight: '15px' }}>
              <span style={{ paddingRight: '5px' }}>Year</span>
              <select onChange={this.handleYearChange} defaultValue={2020}>
                <option value={START_YEAR}>{START_YEAR}</option>
                <option value={2020}>2020</option>
              </select>
            </span>
            <span style={{ paddingRight: '15px' }}>
              <span style={{ paddingRight: '5px' }}>Stream</span>
              <select onChange={this.handleStreamChange}>
                <option value="all">All</option>
                <option value="tw">TW</option>
                <option value="dale">Dale</option>
              </select>
            </span>
            <span>
              <span style={{ paddingRight: '5px' }}>Supplier</span>
              <select onChange={this.handleFactoryChange}>
                <option value="all">All</option>
                <option value="pinus">Pinus</option>
                <option value="byko">Byko-Lat</option>
                <option value="tiptree">Tiptree</option>
                <option value="mumford">Mumford</option>
              </select>
            </span>
            {currentReviewsByYear &&
              currentReviewsByYear[selectedYear] && (
                <QaStatusChart
                  chartData={currentReviewsByYear[selectedYear]}
                  XAxisDataKey="arrival_week"
                  YAxisDataKey1={`qa_passed_${stream}_${factory}`}
                  YAxisDataKey2={`qa_failed_${stream}_${factory}`}
                  YAxisDataKey3={`qa_todo_${stream}_${factory}`}
                  brush={{ dataKey: 'arrival_week' }}
                />
              )}

            {supplierOrderPassRateByYear &&
              supplierOrderPassRateByYear && (
                <ReviewChart
                  chartData={currentReviewsByYear[selectedYear]}
                  XAxisDataKey="arrival_week"
                  YAxisDataKey1={`qa_passed_${stream}_${factory}`}
                  YAxisDataKey2={`qa_failed_${stream}_${factory}`}
                  YAxisDataKey3={`qa_todo_${stream}_${factory}`}
                  brush={{ dataKey: 'arrival_week' }}
                />
              )}
            {itemPassRateByYear &&
              itemPassRateByYear && (
                <ReviewChart
                  chartData={currentReviewsByYear[selectedYear]}
                  XAxisDataKey="arrival_week"
                  YAxisDataKey1={`qa_passed_${stream}_${factory}`}
                  YAxisDataKey2={`qa_failed_${stream}_${factory}`}
                  YAxisDataKey3={`qa_todo_${stream}_${factory}`}
                  brush={{ dataKey: 'arrival_week' }}
                />
              )}
          </div>
        )}
      </AppContent>
    );
  }
}

export default QAChart;
