import styled from "styled-components";

const AppContentContainer = styled.div`
  position: absolute;
  top: 89px;
  border: 1px solid #bfbfbf;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: top;
`;

export default AppContentContainer;
