import styled from "styled-components";
import { theme } from "../../constants";

const HeaderTitle = styled.div`
  color: ${theme.colors.dashboardTitle};
  font-size: 1.6em;
  letter-spacing: 2.5px;
`;

export default HeaderTitle;
