import React from 'react';
import { Link } from 'react-router-dom';

import AppContent from './AppContent';
import { getSupplierOrdersWithFailingChecks } from '../data';
import PageHeading from './PageHeading';

class FailingReviews extends React.Component {
  timer = null;
  _isMounted = false;

  state = {
    organisedReviews: {},
    dataTime: '',
    isLoading: true
  };

  componentDidMount = async () => {
    this.getLatestSupplierOrdersWithFailingChecks();
    this._isMounted = true;
    // this.timer = setInterval(() => this.getLatestSupplierOrdersWithFailingChecks(), 1000);
  };

  componentWillUnmount = async () => {
    clearInterval(this.timer);
    this.timer = null;
    this._isMounted = false;
  };

  getLatestSupplierOrdersWithFailingChecks = async () => {
    const response = await getSupplierOrdersWithFailingChecks(this.props.token);
    let supplierOrdersWithFailingChecks = [];
    if (response && response.length > 0) {
      supplierOrdersWithFailingChecks = response;
    }
    const dateTime = new Date();
    const organisedReviews = this.organiseReviews(supplierOrdersWithFailingChecks);

    if (this._isMounted) {
      await this.setState({
        organisedReviews,
        dateTime: dateTime.toUTCString(),
        isLoading: false
      });
    }
  };

  organiseReviews = supplierOrders => {
    let reviews = { tw: [], dale: [], others: [] };
    for (let i = 0; i < supplierOrders.length; i++) {
      let reviewRow = (
        <div
          key={supplierOrders[i].supplier_ref}
          style={{
            marginTop: '5px',
            padding: '5px',
            display: 'flex'
          }}
        >
          <div style={{ width: '50%' }}>
            <Link to={`/failingChecks/${supplierOrders[i].order_id}/${supplierOrders[i].sup_id}`}>
              {supplierOrders[i].supplier_ref}
            </Link>
            {supplierOrders[i].original_order_ref && (
              <div>Original Order Ref: {supplierOrders[i].original_order_ref}</div>
            )}
          </div>
          <div style={{ width: '50%' }}>{supplierOrders[i].title}</div>
        </div>
      );
      if (supplierOrders[i].stream_code === 'opt') {
        reviews.tw.push(reviewRow);
      } else if (supplierOrders[i].stream_code === 'dale') {
        reviews.dale.push(reviewRow);
      }
    }
    return reviews;
  };

  render() {
    const { dateTime, organisedReviews, isLoading } = this.state;
    return (
      <AppContent>
        <PageHeading>Logistics App - Live Check Results</PageHeading>
        {isLoading && 'Loading'}
        {!isLoading && (
          <div>
            <p>List of supplier orders with items that currently have failed checks</p>
            <p>
              Last checked at {dateTime}
              <button
                style={{ float: 'right' }}
                onClick={this.getLatestSupplierOrdersWithFailingChecks}
              >
                Refresh
              </button>
            </p>
            <hr />
            <h3>Timber Windows</h3>
            {organisedReviews.tw.length === 0 && 'Currently no failing TW Supplier Orders'}
            {organisedReviews.tw.length > 0 && organisedReviews.tw}
            <h3>Dale</h3>
            {organisedReviews.dale.length === 0 && 'Currently no failing Dale Supplier Orders'}
            {organisedReviews.dale.length > 0 && organisedReviews.dale}
          </div>
        )}
      </AppContent>
    );
  }
}

export default FailingReviews;
