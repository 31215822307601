import React from 'react';
import queryString from 'query-string';
import { withRouter, Route } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import AppContainer from './components/AppContainer';
import AppContentContainer from './components/AppContentContainer';
import { authenticateToken, getPersonDetails } from './data';
import FailingChecks from './components/FailingChecks';
import FailingReviews from './components/FailingReviews';
import Header from './components/header/header';
import SideMenu from './components/sideMenu/sideMenu';

import QAStatistics from './components/qaStatistics';
import QAChart from './components/qaChart';

class App extends React.Component {
  state = {
    isLoggingIn: true,
    isLoggedIn: false,
    isProblemAuthenticating: false,
    devReturnString: null,
    token: '',
    name: ''
  };

  componentDidMount = async () => {
    let devReturnString = null;
    if (this.props.cookies.get('ptpgToken') !== undefined) {
      this.authenticate(this.props.cookies.get('ptpgToken'));
      if (this.props.cookies.get('devReturnString') !== undefined) {
        devReturnString = this.props.cookies.get('devReturnString');
      }
    } else {
      let queryStringValues = queryString.parse(this.props.location.search.substr(1));
      if (queryStringValues.key) {
        this.authenticate(queryStringValues.key);
      }
      if (queryStringValues.devAPIServer) {
        this.props.cookies.remove('devReturnString');
        devReturnString = queryStringValues.devAPIServer;
        this.props.cookies.set('devReturnString', devReturnString, {
          path: '/',
          maxAge: 36000 // 10 hours expiry
        });
      }
    }
    await this.setState({
      isLoggingIn: false,
      devReturnString
    });
  };

  authenticate = async token => {
    let authResult = await authenticateToken(token);
    if (authResult && authResult.status === 200) {
      await this.saveAuthenticationCookie(token);

      let personDetails = null;
      const response = await getPersonDetails(token);
      if (response) {
        personDetails = response;
      }

      await this.setState({
        isLoggingIn: false,
        isLoggedIn: true,
        token: token,
        name: personDetails ? personDetails.name : ''
      });
    } else {
      await this.setState({
        isLoggingIn: false,
        isProblemAuthenticating: true
      });
    }
  };

  logout = async () => {
    // TODO: this logout function should actually call a logout endpoint on the auth database, as they are still logged in there, and can still go to the controlcentre
    this.removeAuthenticationCookie();
    await this.setState({
      isLoggedIn: false,
      name: '',
      token: ''
    });
  };

  removeAuthenticationCookie = () => {
    this.props.cookies.remove('ptpgToken');
  };

  saveAuthenticationCookie = token => {
    this.props.cookies.set('ptpgToken', token, {
      path: '/',
      maxAge: 36000 // 10 hours expiry
    });
  };

  render() {
    const {
      devReturnString,
      isLoggedIn,
      isLoggingIn,
      isProblemAuthenticating,
      name,
      token
    } = this.state;
    if (!isLoggedIn) {
      return (
        <AppContainer>
          <Header devReturnString={devReturnString} />
          <AppContentContainer>
            <div style={{ textAlign: 'left', padding: '40px' }}>
              {isProblemAuthenticating && (
                <div style={{ paddingBottom: '30px' }}>
                  Your session token is incorrect or expired.
                </div>
              )}
              {isLoggingIn ? (
                'Authenticating'
              ) : (
                <div>
                  You are not logged in. Please log in to the{' '}
                  <a href={`https://dashboard.thepwgroup.net/`}>PWG dashboard</a> and click
                  'Logistics' to access this page.
                </div>
              )}
            </div>
          </AppContentContainer>
        </AppContainer>
      );
    } else {
      return (
        <AppContainer>
          <Header devReturnString={devReturnString} />
          <AppContentContainer>
            <SideMenu name={name} devReturnString={devReturnString} logout={this.logout} />
            <Route path="/" exact render={props => <FailingReviews {...props} token={token} />} />
            <Route
              path="/failingChecks/:order_id/:supplier_id/:review_id"
              exact
              render={props => <FailingChecks {...props} token={token} />}
            />
            <Route
              path="/failingChecks/:order_id/:supplier_id/"
              exact
              render={props => <FailingChecks {...props} token={token} />}
            />
            <Route
              path="/statistics"
              exact
              render={props => <QAStatistics {...props} token={token} />}
            />
            <Route path="/graphs" exact render={props => <QAChart {...props} token={token} />} />
          </AppContentContainer>
        </AppContainer>
      );
    }
  }
}

export default withRouter(withCookies(App));
