import React, { Component } from 'react';
import moment from 'moment';

import DateDisplay from './DateDisplay';
import HeaderContainer from './HeaderContainer';
import HeaderControls from './HeaderControls';
import HeaderTab from './headerTab';
import HeaderTitle from './HeaderTitle';

import logo from '../../images/pwg-tree-logo-90-82.png';
import { headerDateFormat, appVersionTitle } from '../../constants';

class Header extends Component {
  state = {
    isAppMenuShown: false,
    isUserPanelShown: false
  };

  logout = () => {
    this.toggleUserPanel();
    this.props.logout();
  };

  hidePopups = async e => {
    if (e.target.dataset.action === 'logout') this.logout();
    if (e.target.dataset.action === 'showAllVersionUpdates') this.props.showAllVersionUpdates();
    await this.setState({
      isAppMenuShown: false,
      isUserPanelShown: false
    });
  };

  toggleAppMenu = async () => {
    await this.setState({
      isAppMenuShown: !this.state.isAppMenuShown,
      isUserPanelShown: false
    });
  };

  toggleUserPanel = async () => {
    await this.setState({
      isAppMenuShown: false,
      isUserPanelShown: !this.state.isUserPanelShown
    });
  };

  render() {
    // const baseUrl = this.props.devReturnString
    //   ? `https://${this.props.devReturnString}`
    //   : 'https://dashboard.thepwgroup.net';
    const baseUrl = 'https://dashboard.thepwgroup.net';
    return (
      <HeaderContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt={'logo'} />
          <HeaderControls>
            <HeaderTitle>PWG CONTROL CENTRE</HeaderTitle>
            <div style={{ marginLeft: '10px', color: '#ff0000' }}>{appVersionTitle}</div>
            <DateDisplay ml={3} p={1}>
              {moment().format(headerDateFormat)}
            </DateDisplay>
          </HeaderControls>
        </div>
        <HeaderTab
          isActive
          rightOffset="-2px"
          label="TW"
          link={`${baseUrl}/control/control_center.cgi`}
        />
        <HeaderTab
          rightOffset="94px"
          label="Dale"
          link={`${baseUrl}/control/control_center_dl_dashboard.cgi`}
        />
        <HeaderTab
          rightOffset="192px"
          label="PWG"
          link={`${baseUrl}/control/control_center_pwg_dashboard.cgi`}
        />
      </HeaderContainer>
    );
  }
}

export default Header;
