import React from 'react';
import styled from "styled-components";

import { theme } from "../../constants";

const SideMenuItemContainer = styled.div`
  color: ${props => props.disabled ? '#898989' : '#000000'};
  background-color: ${props => props.white ? '#ffffff' : (
    props.isSelected ? theme.colors.sideMenu.backgroundSelected : (
      props.separator ? theme.colors.dateBackground : (
        props.isSubMenuItem ? theme.colors.sideMenu.backgroundSubmenu : (
          props.isGrey ? theme.colors.sideMenu.backgroundGrey : theme.colors.sideMenu.backgroundUnselected
        )
      )
    )
  )};
  padding: ${props => props.separator ? "0px" : (
    props.isSubMenuItem ? "8px 8px 8px 30px" : "8px 8px 8px 16px")
  };
  border-bottom: 1px solid ${theme.colors.sideMenu.borderBottom};
  height: ${props => props.separator ? "5px" : "auto"};
  font-weight: ${props => props.isSelected ? "bold" : "normal"}
  cursor: pointer;
  
  & > a {
    color: ${props => props.disabled ? '#898989' : '#000000'};
    text-decoration: none;
  }
  
  &: hover {
    text-decoration: ${props => props.noLink ? 'none' : 'underline'};
  }
`;

class SideMenuItem extends React.Component {
  render() {
    return (
      <SideMenuItemContainer {...this.props}>
        {this.props.children}
      </SideMenuItemContainer>
    )
  }
}

export default SideMenuItem;

