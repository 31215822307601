import React from 'react';
import { Link } from 'react-router-dom';

import Flex from '../shared/Flex';
import SideMenuContainer from './SideMenuContainer';
import SideMenuItem from './SideMenuItem';
import smiley from '../../images/smile.gif';

class SideMenu extends React.Component {
  render() {
    const baseUrl = 'https://dashboard.thepwgroup.net';
    return (
      <SideMenuContainer>
        <SideMenuItem noLink white>
          <Flex>
            <div>Welcome {this.props.name}</div>
            <div>
              <img src={smiley} alt={'smiley'} />
            </div>
          </Flex>
        </SideMenuItem>
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center.cgi`}>Dashboard</a>
        </SideMenuItem>
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_enquiries.cgi`}>Enquiries</a>
        </SideMenuItem>
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_quotes.cgi`}>Quotes</a>
        </SideMenuItem>
        <SideMenuItem separator />
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_orders_recent.cgi`}>Orders</a>
        </SideMenuItem>
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_suppliers.cgi`}>Suppliers</a>
        </SideMenuItem>
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_supplier_orders_funnel.cgi`}>
            Supplier Orders
          </a>
        </SideMenuItem>
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_service_requests.cgi`}>Service Requests</a>
        </SideMenuItem>
        <SideMenuItem separator />
        <SideMenuItem isSelected>
          <Link to={`/`}>Logistics</Link>
        </SideMenuItem>
        <SideMenuItem isSubMenuItem>
          <Link to={`/`}>Live Check Results</Link>
        </SideMenuItem>
        <SideMenuItem isSubMenuItem>
          <Link to={`/statistics`}>All Failed Checks</Link>
        </SideMenuItem>
        <SideMenuItem isSubMenuItem>
          <Link to={`/graphs`}>View graphs</Link>
        </SideMenuItem>
        <SideMenuItem separator />
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_installers.cgi`}>Installers</a>
        </SideMenuItem>
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_salespeople.cgi`}>Salespeople</a>
        </SideMenuItem>
        <SideMenuItem separator />
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_reports.cgi`}>Reports</a>
        </SideMenuItem>
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_staff.cgi`}>Staff</a>
        </SideMenuItem>
        <SideMenuItem separator />
        <SideMenuItem>
          <a href={`${baseUrl}/control/control_center_settings.cgi`}>Settings</a>
        </SideMenuItem>
        <SideMenuItem separator />
        <SideMenuItem isGrey>
          <a href={`https://my.timberwindows.com/installers/`}>Staff Intranet</a>
        </SideMenuItem>
        <SideMenuItem separator />
        <SideMenuItem onClick={this.props.logout}>Logout</SideMenuItem>
      </SideMenuContainer>
    );
  }
}

export default SideMenu;
