import React from 'react';

import AppContent from './AppContent';
import FailingCheckTable from './FailingCheckTable';
import { getChecksFromSupplierOrder } from '../data';

class FailingChecks extends React.Component {
  state = {
    checksArray: [],
    isShowOnlyFailedChecks: true,
    supplierOrderId: -1,
    supplierOrderReference: '',
    isLoading: true
  };

  componentDidMount = async () => {
    const checksResponse = await getChecksFromSupplierOrder(
      this.props.token,
      this.props.match.params.order_id,
      this.props.match.params.supplier_id,
      this.props.match.params.review_id
    );

    let checksArray = [];
    if (checksResponse && checksResponse.length > 0) {
      for (let i = 0; i < checksResponse.length; i++) {
        const failingChecks = checksResponse[i].filter(check => {
          return check.answer === '0';
        });
        const passingChecks = checksResponse[i].filter(check => {
          return check.answer === '1';
        });

        let itemCode = '';
        let product_title = '';
        if (checksResponse[i].length > 0) {
          itemCode = checksResponse[i][0].itemcodes;
          product_title = checksResponse[i][0].product_title;
        }

        checksArray.push({
          failingChecks,
          passingChecks,
          product_title,
          itemCode
        });
      }
    }
    await this.setState({
      checksArray,
      isLoading: false
    });
  };

  handleCheckbox = event => {
    this.setState({
      [event.currentTarget.name]: !this.state[event.currentTarget.name]
    });
  };

  render() {
    const { checksArray, isShowOnlyFailedChecks, isLoading } = this.state;
    return (
      <AppContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <button onClick={this.props.history.goBack}>Go Back</button>
          </div>
          <div>
            <input
              id="isShowOnlyFailedChecks"
              type="checkbox"
              name="isShowOnlyFailedChecks"
              checked={isShowOnlyFailedChecks}
              onChange={this.handleCheckbox}
            />
            <label htmlFor="isShowCorrectChecks">
              <span>Show only failed checks?</span>
            </label>
          </div>
        </div>
        {isLoading && <div style={{ marginTop: '30px' }}>Loading</div>}
        {!isLoading &&
          checksArray.length === 0 && (
            <div style={{ marginTop: '30px' }}>No checks found for this supplier order</div>
          )}
        {!isLoading && (
          <FailingCheckTable
            checksArray={checksArray}
            isShowOnlyFailedChecks={isShowOnlyFailedChecks}
          />
        )}
      </AppContent>
    );
  }
}

export default FailingChecks;
