import { control_centre_server } from './middleware_connections';
const DEV_API_SERVER = 'aperture.staging0.timberwindows.com';
const IS_DEV = false;

export const authenticateToken = async token => {
  const params = {
    token: token
  };
  // const authenticateTokenResult = await control_centre_server.call('/authenticateToken', params);
  const authenticateTokenResult = await control_centre_server.call(
    '/logistics/authenticateToken',
    params
  );
  return authenticateTokenResult;
};

export const getPersonDetails = async token => {
  // const params = {
  //   token: token
  // };
  // const result = await database.call('/logistics/getPersonDetails', params);
  return { name: 'User' };
};

export const getSupplierOrdersWithFailingChecks = async token => {
  const params = {
    token: token,
    startWeek: 0,
    yearOfStartWeek: 2019,
    endWeek: 55,
    yearOfEndWeek: 2020
  };
  if (IS_DEV) {
    params.devAPIServer = DEV_API_SERVER;
  }
  const response = await control_centre_server.call(
    '/logistics/getSupplierOrdersWithFailingChecks',
    params
  );
  let supplierOrdersWithFailingChecks = [];
  if (response && response.data && response.data.supplierOrdersResponse) {
    supplierOrdersWithFailingChecks = response.data.supplierOrdersResponse.firstHalf;
    if (response.data.supplierOrdersResponse.secondHalf !== '') {
      supplierOrdersWithFailingChecks = supplierOrdersWithFailingChecks.concat(
        response.data.supplierOrdersResponse.secondHalf
      );
    }
  }
  return supplierOrdersWithFailingChecks;
};

export const getChecksFromSupplierOrder = async (token, orderId, supplierId, reviewId) => {
  const params = {
    token: token,
    orderId: orderId,
    supplierId: supplierId,
    reviewId: reviewId
  };
  if (IS_DEV) {
    params.devAPIServer = DEV_API_SERVER;
  }

  const response = await control_centre_server.call(
    '/logistics/getChecksFromSupplierOrder',
    params
  );
  let checks = [];
  if (
    response &&
    response.data &&
    response.data.singleSupplierOrderResponse &&
    response.data.singleSupplierOrderResponse.qaReview
  ) {
    checks = response.data.singleSupplierOrderResponse.qaReview;
  }

  return checks;
};

export const getQADataForGraph = async (token, orderId, supplierId) => {
  const params = {
    token: token
  };
  if (IS_DEV) {
    params.devAPIServer = DEV_API_SERVER;
  }

  const response = await control_centre_server.call('/logistics/getQADataForGraph', params);
  return response;
};

export const getReviewData = async (token, orderId, supplierId) => {
  const params = {
    token: token
  };
  if (IS_DEV) {
    params.devAPIServer = DEV_API_SERVER;
  }
  const response = await control_centre_server.call('/logistics/getReviewData', params);
  return response;
};
